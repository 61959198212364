<template>
  <div class="home-blank">
    <template v-if="!loading">
      <div v-if="indexData.banner_1 && indexData.banner_1.length > 0" class="banner-blank">
        <el-carousel :indicator-position="indexData.banner_1.length < 2 ? 'none' : ''" height="400px" arrow="never">
          <el-carousel-item v-for="item in indexData.banner_1" :key="item.id">
            <div @click="toNavUrl(item)" class="banner-item" :style="{'background-image' : 'url(' + item.image +')'}">
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="content-wrap">
        <div v-if="indexData.banner_2 && indexData.banner_2.length > 0" class="function-blank">
          <div
            class="item-blank"
            :style="{'display' : index > 3 ? 'none' : 'flex' }"
            v-for="(item, index) in indexData.banner_2"
            :key="index"
            @click="toNavUrl(item)"
          >
            <img :src="item.image" />
            <div class="content">
              <span>{{ item.name }}</span>
              <p>{{ item.brief }}</p>
            </div>
          </div>
        </div>
        <div v-if="liveList.length > 0" class="module-blank">
          <div class="module-title">
            <div class="title-wrap">
              <div class="line" />
              <span>近期直播</span>
            </div>
            <div class="more" @click="toLiveUrl()">
              <span>查看更多</span>
              <img src="../assets/img/more_icon.png" />
            </div>
          </div>
          <div class="live-blank">
            <div class="live-item" v-for="(value, index) in liveList" :key="index" @click="toLiveDetail(value)">
              <div class="title-wrap">
                <span v-if="value.is_free === 1" class="free-state">免费</span>
                <span v-if="value.is_free === 2" class="free-state" style="background-color: #7438ef;">密码</span>
                <span class="live-title">{{ value.name }}</span>
              </div>
              <span class="live-date">{{ value.start_time | getYMDHMS('yyyy-MM-dd hh:mm') }}</span>
              <div class="live-info">
                <div class="live-teacher-wrap">
                  <template v-if="value.teachers && value.teachers.length > 0">
                    <div v-for="(teacher, teaIndex) in value.teachers.slice(0, 3)" :key="teaIndex" :class="{'live-teacher-blank' : teaIndex > 0}">
                      <img v-if="teacher.avatar" class="live-teacher" :src="teacher.avatar" />
                      <img v-else class="live-teacher" src="../assets/img/avtar_icon.jpg" />
                    </div>
                  </template>
                </div>
                <template>
                  <div v-if="value.live_status === 0" class="live-state live-state-before">
                    <img src="../assets/img/live_before_icon.png" />
                    <span>未开始</span>
                  </div>
                  <div v-if="value.live_status === 1" class="live-state live-state-start">
                    <img src="../assets/img/live_start_icon.png" />
                    <span>直播中</span>
                  </div>
                  <div  v-if="value.live_status === 3" class="live-state live-state-end">
                    <img src="../assets/img/live_end_icon.png" />
                    <span>已结束</span>
                  </div>
                  <div  v-if="value.live_status === 5" class="live-state live-state-backing">
                    <img src="../assets/img/live_end_icon.png" />
                    <span>回放中</span>
                  </div>
                </template>
                <div class="right">
                  <span v-if="value.is_free == 0" class="price">￥{{ value.price }}</span>
                  <span class="count">{{ value.virtually_pay_num !== '0' && value.virtually_pay_num  !== '' ? value.virtually_pay_num : value.user_count }}人已报名</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="(item, index) in indexData.module"
          :key="index" class="module-blank"
          :style="{'display' : item.connect_course.length === 0 && item.connect_package.length === 0 ? 'none' : 'block' }"
        >
          <div class="module-title">
            <div class="title-wrap">
              <div class="line" />
              <span>{{ item.name }}</span>
            </div>
            <div v-if="item.is_show_more === 1" class="more" @click="toModuleList(item)">
              <span>查看更多</span>
              <img src="../assets/img/more_icon.png" />
            </div>
          </div>
          <div v-if="item.typesetting === 1" class="course-blank">
            <template>
              <div v-if="item.connect_course.length > 0" class="course-left" @click="toDetailUrl(item.connect_course[0])">
                <img
                  v-if="item.connect_course[0].course.pc_cover2"
                  :src="item.connect_course[0].course.pc_cover2"
                />
                <default-cover v-else />
              </div>
              <div v-if="item.connect_course.length === 0 && item.connect_package.length > 0" class="course-left" @click="toPackageDetailUrl(item.connect_package[0])">
                <img
                  v-if="item.connect_package[0].package[0].pc_cover2"
                  :src="item.connect_package[0].package[0].pc_cover2"
                />
                <default-cover v-else />
              </div>
            </template>
            <template>
              <div v-if="item.connect_course.length >= 1" class="course-right">
                <div
                  class="course-item"
                  v-for="(val, valIndex) in item.connect_course.slice(1)"
                  :key="valIndex"
                  :style="{'display' : val.course ? 'block' : 'none' }"
                  @click="toDetailUrl(val)"
                >
                  <template v-if="val.course">
                    <img v-if="val.course.pc_cover1" class="cover" :src="val.course.pc_cover1" />
                    <div v-else class="cover">
                      <default-cover />
                    </div>
                    <div class="info-wrap">
                      <div class="title">{{ val.course.name }}</div>
                      <div class="info">
                        <span>{{ val.course.virtually_pay_num !== '0' && val.course.virtually_pay_num  !== '' ? val.course.virtually_pay_num : val.course.course_user1_count }}人已报名</span>
                        <div class="info-price">
                          <template v-if="isActivity(val.course.activity_expire_time)">
                            <p class="origin-price">￥{{ val.course.price }}</p>
                            <p :class="{'price' : parseFloat(val.course.activity_price) > 0}">{{parseFloat(val.course.activity_price) > 0 ? '￥' + val.course.activity_price : '免费'}}</p>
                          </template>
                          <template v-else>
                            <p v-if="val.course.is_free === 1">免费</p>
                            <p v-else class="price">￥{{ val.course.price }}</p>
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div
                  class="course-item"
                  v-for="val in item.connect_package"
                  :key="val.id"
                  :style="{'display' : val.package && val.package.length > 0 ? 'block' : 'none' }"
                  @click="toPackageDetailUrl(val)"
                >
                  <template v-if="val.package && val.package.length > 0">
                    <img v-if="val.package[0].pc_cover1" class="cover" :src="val.package[0].pc_cover1" />
                    <div v-else class="cover">
                      <default-cover />
                    </div>
                    <div class="info-wrap">
                      <div class="title">{{ val.package[0].name }}</div>
                      <div class="info">
                        <span>{{ val.package[0].virtually_pay_num !== '0' && val.package[0].virtually_pay_num  !== '' ? val.package[0].virtually_pay_num : val.package[0].package_user1_count }}人已报名</span>
                        <div class="info-price">
                          <template v-if="isActivity(val.package[0].activity_expire_time)">
                            <p class="origin-price">￥{{ val.package[0].price }}</p>
                            <p :class="{'price' : parseFloat(val.package[0].activity_price) > 0}">{{parseFloat(val.package[0].activity_price) > 0 ? '￥' + val.package[0].activity_price : '免费'}}</p>
                          </template>
                          <template v-else>
                            <p v-if="val.package[0].is_free === 1">免费</p>
                            <p v-else class="price">￥{{ val.package[0].price }}</p>
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div v-if="item.connect_course.length === 0 && item.connect_package.length > 1" class="course-right">
                <div
                  class="course-item"
                  v-for="(val, valIndex) in item.connect_package.slice(1)"
                  :key="valIndex"
                  :style="{'display' : val.package && val.package.length > 0 ? 'block' : 'none' }"
                  @click="toPackageDetailUrl(val)"
                >
                  <template v-if="val.package && val.package.length > 0">
                    <img v-if="val.package[0].pc_cover1" class="cover" :src="val.package[0].pc_cover1" />
                    <div v-else class="cover">
                      <default-cover />
                    </div>
                    <div class="info-wrap">
                      <div class="title">{{ val.package[0].name }}</div>
                      <div class="info">
                        <span>{{ val.package[0].virtually_pay_num !== '0' && val.package[0].virtually_pay_num  !== '' ? val.package[0].virtually_pay_num : val.package[0].package_user1_count }}人已报名</span>
                        <div class="info-price">
                          <template v-if="isActivity(val.package[0].activity_expire_time)">
                            <p class="origin-price">￥{{ val.package[0].price }}</p>
                            <p :class="{'price' : parseFloat(val.package[0].activity_price) > 0}">{{parseFloat(val.package[0].activity_price) > 0 ? '￥' + val.package[0].activity_price : '免费'}}</p>
                          </template>
                          <template v-else>
                            <p v-if="val.package[0].is_free === 1">免费</p>
                            <p v-else class="price">￥{{ val.package[0].price }}</p>
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
          <div v-if="item.typesetting === 2" class="course-blank learn-blank">
            <div class="course-right">
              <div
                class="course-item"
                v-for="val in item.connect_course"
                :key="val.id"
                :style="{'display' : val.course ? 'block' : 'none' }"
                @click="toDetailUrl(val)"
              >
                <template v-if="val.course">
                  <img v-if="val.course.pc_cover1" class="cover" :src="val.course.pc_cover1" />
                  <div class="cover" v-else>
                    <default-cover />
                  </div>
                  <div class="info-wrap">
                    <div class="title">{{ val.course.name }}</div>
                    <div class="info">
                      <span>{{ val.course.virtually_pay_num !== '0' && val.course.virtually_pay_num  !== '' ? val.course.virtually_pay_num : val.course.course_user1_count }}人已报名</span>
                      <div class="info-price">
                        <template v-if="isActivity(val.course.activity_expire_time)">
                          <p class="origin-price">￥{{ val.course.price }}</p>
                          <p :class="{'price' : parseFloat(val.course.activity_price) > 0}">{{parseFloat(val.course.activity_price) > 0 ? '￥' + val.course.activity_price : '免费'}}</p>
                        </template>
                        <template v-else>
                          <p v-if="val.course.is_free === 1">免费</p>
                          <p v-else class="price">￥{{ val.course.price }}</p>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div
                class="course-item"
                v-for="val in item.connect_package"
                :key="val.id"
                :style="{'display' : val.package && val.package.length > 0 ? 'block' : 'none' }"
                @click="toPackageDetailUrl(val)"
              >
                <template v-if="val.package && val.package.length > 0">
                  <img v-if="val.package[0].pc_cover1" class="cover" :src="val.package[0].pc_cover1" />
                  <div class="cover" v-else>
                    <default-cover />
                  </div>
                  <div class="info-wrap">
                    <div class="title">{{ val.package[0].name }}</div>
                    <div class="info">
                      <span>{{ val.package[0].virtually_pay_num !== '0' && val.package[0].virtually_pay_num  !== '' ? val.package[0].virtually_pay_num : val.package[0].package_user1_count }}人已报名</span>
                      <div class="info-price">
                        <template v-if="isActivity(val.package[0].activity_expire_time)">
                          <p class="origin-price">￥{{ val.package[0].price }}</p>
                          <p :class="{'price' : parseFloat(val.package[0].activity_price) > 0}">{{parseFloat(val.package[0].activity_price) > 0 ? '￥' + val.package[0].activity_price : '免费'}}</p>
                        </template>
                        <template v-else>
                          <p v-if="val.package[0].is_free === 1">免费</p>
                          <p v-else class="price">￥{{ val.package[0].price }}</p>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div v-if="index === 0 && indexData.banner_3 && indexData.banner_3.length > 0" class="course-banner">
            <el-carousel height="160px" arrow="never">
              <el-carousel-item v-for="val in indexData.banner_3" :key="val.id">
                <img class="course-banner-item" :src="val.image" @click="toNavUrl(val)" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div v-if="indexData.banner_4 && indexData.banner_4.length > 0" class="module-blank">
          <div class="module-title">
            <div class="title-wrap">
              <div class="line" />
              <span>学习工具</span>
            </div>
          </div>
          <div class="tool-blank">
            <div class="tool-item" :id="value.url_type === 7 ? 'tool-item-code' : ''" v-for="(value, index) in indexData.banner_4" :key="index" @click="toNavUrl(value)">
              <div class="left">
                <span>{{ value.name }}</span>
                <p>{{ value.brief }}</p>
              </div>
              <img class="tool-icon" :src="value.image" />
              <img class="tool-code" :src="value.code_image" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <loading :is-load="loading" />
  </div>
</template>

<script>
import * as courseService from '@/service/course-service';

export default {
  name: 'homePage',
  data() {
    return {
      loading: false,
      indexData: {}, // 首页模块数据
      liveList: [] // 近期直播
    };
  },
  mounted() {
    this.getLiveList();
    this.getIndexData();
  },
  methods: {
    /**
     * 获取首页
     * */
    getIndexData() {
      this.loading = true;
      courseService.getIndexData().then(res => {
        this.loading = false;
        if (res.code === 1 && res.data) {
          this.indexData = res.data;
          if (this.indexData.module) {
            this.indexData.module.map(item => {
              if (item.connect_course.length > item.home_limit_course_num) {
                item.connect_course.splice(item.home_limit_course_num, 1);
              }
              if (item.connect_package.length > item.home_limit_package_num) {
                item.connect_package.splice(item.home_limit_package_num, 1);
              }
            });
          }
        }
      });
    },
    /**
     * 获取近期直播
     * */
    getLiveList() {
      const data = {
        course_type: 3
      };
      courseService.getCourseSearch(data).then(res => {
        if (res.code === 1) {
          this.liveList = res.data;
        }
      });
    },
    /**
     * banner跳转
     * */
    toNavUrl(info) {
      // 课程
      if (info.url_type === 3) {
        this.$router.push({
          path: '/courseDetail',
          query: {
            id: info.url_data.id
          }
        });
      }
      // 套课
      if (info.url_type === 4) {
        this.$router.push({
          path: '/packageDetail',
          query: {
            id: info.url_data.id
          }
        });
      }
      // 课程列表
      if (info.url_type === 5) {
        this.$router.push({
          path: '/courseSelection'
        });
      }
      // 外链
      if (info.url_type === 1) {
        window.open(info.url_data, '_blank');
      }
    },
    /**
     * 直播详情
     * */
    toLiveDetail(info) {
      this.$router.push({
        path: '/courseDetail',
        query: {
          id: info.id
        }
      });
    },
    /**
     * 直播列表
     * */
    toLiveUrl() {
      this.$router.push({
        path: '/liveList'
      });
    },
    /**
     * 课程详情
     * */
    toDetailUrl(info) {
      const path = info.course.course_type === '0' ? '/packageDetail' : '/courseDetail';
      this.$router.push({
        path: path,
        query: {
          id: info.course.id
        }
      });
    },
    /**
     * 套课详情
     * */
    toPackageDetailUrl(info) {
      this.$router.push({
        path: 'packageDetail',
        query: {
          id: info.package[0].id
        }
      });
    },
    /**
     * 模块列表
     * */
    toModuleList(info) {
      this.$router.push({
        path: '/moduleList',
        query: {
          id: info.id,
          name: info.name
        }
      });
    },
    /**
     * 课程是否限时活动
     * */
    isActivity(activityDate) {
      const curTime = new Date().getTime();
      const activityTime = new Date(activityDate).getTime();
      if (activityTime - curTime > 0) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.home-blank {
  width: 100%;
  line-height: 1;
  .banner-blank {
    height: 400px;
    .banner-item {
      height: 400px;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      cursor: pointer;
      .content-wrap {
        position: relative;
        height: 100%;
        .view-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 87px;
          bottom: 59px;
          width: 180px;
          height: 46px;
          background: #F8B135;
          border-radius: 23px;
          font-size: 22px;
          color: #FFFFFF;
          cursor: pointer;
        }
      }

    }
    ::v-deep .el-carousel__button{
      opacity: 0.1;
    }
    ::v-deep .el-carousel__indicator.is-active button {
      opacity: 0.6;
    }
  }
  .function-blank {
    height: 90px;
    background: #fff;
    padding: 13px 0 9px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 51px;
    margin-top: 30px;
    .item-blank {
      display: flex;
      width: 299px;
      cursor: pointer;
      padding-left: 69px;
      line-height: 1;
      position: relative;
      img {
        width: 68px;
        height: 68px;
        margin-right: 13px;
      }
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        span {
          font-size: 16px;
          color: #333333;
          margin-bottom: 10px;
        }
        p {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
        }
      }
    }
    .item-blank:after {
      position: absolute;
      content: '';
      top: 12px;
      right: 0;
      width: 1px;
      height: 40px;
      background: #F6F6F6;
      border-radius: 1px;
    }
    .item-blank:last-child:after {
      display: none;
    }
  }
  .module-blank {
    margin-top: 31px;
    .module-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;
      .title-wrap {
        display: flex;
        align-items: center;
        .line {
          width: 4px;
          height: 21px;
          background: #2869F5;
          border-radius: 1px;
          margin-right: 12px;
        }
        span {
          font-size: 20px;
          font-weight: bold;
          color: #333333;
        }
      }
      .more {
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          margin-right: 4px;
        }
        img {
          width: 7px;
          height: 13px;
        }
      }
    }
    .live-blank {
      display: flex;
      margin-bottom: 20px;
      .live-item {
        display: flex;
        flex-direction: column;
        width: 388px;
        height: 155px;
        background: #FFFFFF;
        border-radius: 6px;
        padding: 20px 16px;
        line-height: 1;
        cursor: pointer;
        .title-wrap {
          display: flex;
          align-items: center;
          margin-bottom: 14px;
          .free-state {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 22px;
            background: #FB8A00;
            border-radius: 4px;
            margin-right: 10px;
            font-size: 14px;
            color: #FFFFFF;
          }
          .live-title {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 18px;
            color: #222222;
          }
        }
        .live-date {
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          margin-bottom: 20px;
        }
        .live-info{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .live-teacher-wrap {
             display: flex;
            // flex: 1;
            .live-teacher-blank {
              margin-left: 10px;
            }
          }
          .live-teacher {
            width: 38px;
            height: 38px;
            border-radius: 50%;
          }
          .live-state {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 25px;
              width: 80px;
              height: 24px;
              border-radius: 12px;
              img {
                margin-right: 6px;
              }
              span {
                font-size: 14px;
                color: #FFFFFF;
              }
            }
            .live-state-start {
              background: #2869F5;
              img {
                width: 14px;
                height: 15px;
              }
            }
            .live-state-before {
              background: #7438EF;
              img {
                width: 16px;
                height: 16px;
              }
            }
            .live-state-end {
              background: #CFCFCF;
              img {
                width: 16px;
                height: 16px;
              }
            }
            .live-state-backing {
              background: #ffaa00;
              img {
                width: 16px;
                height: 16px;
              }
            }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            min-height: 100%;
            // margin-left: 24px;
            flex: 1;
            .price {
              font-size: 18px;
              font-weight: bold;
              color: #E22323;
              margin-bottom: 15px;
            }
            .count {
              font-size: 16px;
              font-weight: 400;
              color: #666666;
            }
          }
        }
      }
      .live-item+.live-item {
        margin-left: 16px;
      }
      .live-item:hover {
        border: 1px solid #2869F5;
        box-shadow: 2px 4px 9px 0px rgba(40,105,245,0.09);
      }
    }
    .course-blank {
      display: flex;
      .course-left {
        width: 590px;
        height: 520px;
        margin-right: 20px;
        cursor: pointer;
        border-radius: 8px;
        overflow: hidden;
        transition: all .4s;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .course-left:hover {
        box-shadow: 2px 4px 9px 0px rgba(40,105,245,0.09);
        transform: translateY(-5px);
      }
      .course-right {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        .course-item {
          width: 286px;
          height: 250px;
          background: #FFFFFF;
          border-radius: 8px;
          cursor: pointer;
          overflow: hidden;
          line-height: 1;
          margin: 0 18px 20px 0;
          transition: all .4s;
          .cover {
            width: 286px;
            height: 176px;
          }
          .info-wrap {
            padding: 0 16px 0 15px;
            .title {
              margin: 14px 0 10px;
              font-size: 18px;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .info {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .info-price {
                display: flex;
                align-items: center;
                .origin-price {
                  font-size: 14px;
                  font-weight: 400;
                  text-decoration: line-through;
                  color: #999999;
                  margin-right: 9px;
                }
              }
              span {
                font-size: 12px;
                font-weight: 400;
                color: #666666;
              }
              p {
                font-size: 18px;
                color: #E22323;
                font-weight: bold;
              }
              .price {
                font-weight: bold;
              }
            }
          }

        }
        .course-item:nth-child(2n) {
          margin-right: 0;
        }
        .course-item:hover {
          box-shadow: 2px 4px 9px 0px rgba(40,105,245,0.09);
          transform: translateY(-5px);
        }
      }
    }
    .course-banner {
      height: 160px;
      overflow: hidden;
      margin: 50px 0 51px;
      ::v-deep .el-carousel--horizontal {
        overflow: hidden;
      }
      img {
        cursor: pointer;
        width: 1200px;
        height: 160px;
        border-radius: 8px;
      }
      ::v-deep .el-carousel__button{
        width: 6px;
        height: 6px;
        background: #2F70FC;
        border-radius: 3px;
        opacity: 1;
      }
      ::v-deep .el-carousel__indicator.is-active button {
        width: 16px;
        height: 6px;
        background: #2F70FC;
        border-radius: 3px;
      }
    }
    .learn-blank {
      .course-item:nth-child(2n) {
        margin-right: 18px !important;
      }
      .course-item:nth-child(4n) {
        margin-right: 0 !important;
      }
    }
    .tool-blank {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 11px;
      .tool-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 385px;
        height: 130px;
        background: #FFFFFF;
        border: 2px solid #FFFFFF;
        border-radius: 10px;
        padding: 15px 25px 15px 30px;
        line-height: 1;
        cursor: pointer;
        margin-right: 22px;
        margin-bottom: 20px;
        .left {
          display: flex;
          flex-direction: column;
          flex: 1;
          overflow: hidden;
          span {
            font-size: 22px;
            color: #333333;
            margin-left: 1px;
            margin-bottom: 18px;
          }
          p {
            font-size: 18px;
            font-weight: 400;
            color: #828282;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .tool-icon {
          width: 100px;
          height: 100px;
          margin-left: 20px;
        }
        .tool-code {
          display: none;
          width: 100px;
          height: 100px;
          margin-left: 20px;
        }
      }
      #tool-item-code:hover {
        .tool-icon {
          display: none;
        }
        .tool-code {
          display: block;
        }
      }
      .tool-item:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

</style>
